import React from 'react'
import Layout from '../../../components/Layout'
import Nav from '../../../components/Nav'

import timeline from '../../../images/shopify-timeline.png'
import currencyMap from '../../../images/shopify-currency-journey-map.png'
import shopify1 from '../../../images/shopify-mc-checkout-1.png'
import shopify2 from '../../../images/shopify-mc-checkout-2.png'
import shopify3 from '../../../images/shopify-mc-checkout-3.png'

export default function multiCurrency() {
    return (
        <Layout>
            <Nav></Nav>
            <div className="case-study">
                <main>
                    <div className="wrapper">
                        <div className="case-study--hero">
                            <span className="lozenge">Case study</span>
                            <h1>Unlocking local currency for international customers.</h1>
                            <h2>How might we enable payment methods customers are used to on stores that based in other countries?</h2>
                        </div>
                        <section className="case-study--problem">
                            <h3>Definitions</h3>
                            <ul>
                                <li><strong>Shopify</strong> is a global platform for entrepreneurs selling to customers.</li>
                                <li><strong>Shopify Markets</strong> is a product that contains the necessary tools for a merchant to successfully sell their products across international borders.</li>
                                <li><strong>Multi-currency or Foreign Exchange</strong> is when one more more currencies are exchanging hands. A fee is typically involved with type of exchange.</li>
                                <li><strong>Payment Gateway</strong> a mechanism that communicates transaction information between the customer and the merchant <em>(i.e. PayPal, Afterpay, Klarna)</em>.</li>
                            </ul>
                        </section>
                        <section className="case-study--problem">
                            <h3>Team members &amp; responsibilities</h3>
                            <ul>
                                <li>I was the lead designer on the project</li>
                                <li>Three engineers (Staff, Senior, Senior)</li>
                                <li>Product Manager</li>
                                <li>Data Scientist</li>
                                <li>Stakeholder: Head of Product, Shopify Markets</li>
                            </ul>
                        </section>
                        <section>
                            <span className="lozenge">Problem statement</span>
                            <h1>During the month of January and February of 2023, customers who were accustomed to paying with their local currencies through their preferred payment methods were unable to do so.</h1>
                        </section>
                        <section>
                            <img src={timeline} alt="Timeline of events" />
                            <h3 style={{marginBottom:0}}>Earliest signals of problem came a few months ago.</h3>
                            <p><em>Each dot represents a conversation with a merchant.</em></p>
                            <p>In our quantitative data, we were seeing that our churn rate for the Shopify Markets product was increasing steadily. Rising from 10% to 21%.</p>

                            <p><em>Churn rate = merchants turning off Shopify Markets to manage international commerce.</em></p>
                            
                            <p>This obviously caused a bit of concern as this was a source of revenue for the company. <strong>But more importantly, turning off Shopify Markets opens up the possibility for a lot of international selling issues (taxes, duties, currency control, and shipping).</strong></p>
                        </section>
                        <section>
                            <h3>Turns out the rabbit hole gets deeper</h3>
                            <p>More conversations with merchants revealed that those who had adopted to try the new checkout experience automatically disabled payment methods that were natively multi-currency.</p>
                            <p><strong>Effect:</strong> A Canadian customer shopping at a United States based company is no longer able to use Afterpay to use the Canadian dollars they are accustomed to. They are forced to use payment methods that charge in USD.</p>
                            <p><strong>This is not an ideal experience when it comes to matching the expectation of a buyer and the store they are shopping.</strong></p>
                        </section>
                        <section>
                            <h3>The ideal state of a true multi-currency checkout experience is to have DCC enabled.</h3>
                            <p>DCC or Dynamic Currency Conversion enables the customer to pay in whatever currency they prefer and the currency is automatically converted to the shop owner.</p>
                            <p>This is not currently supported by Shopify. More specifically not supported in the new checkout experience.</p>
                            <p><strong>Constraint:</strong> This was the optimal solution but in lieu of this we simply needed to enable all payment gateways on the new checkout experience as soon as possible.</p>
                        </section>
                        <section>
                            <h1><strike>User</strike> Currency Journey Map</h1>
                            <p>To map out a less than ideal experience, it was imperative to map out the experience to see the full journey of currency so that we can visualize the implications of our choices.</p>
                            <img src={currencyMap} alt="Currency Journey Map" />
                            <h3>The happy path</h3>
                            <p>The customer will pay in another currency than the one that they were browsing in. In order to make this more palatable, we wanted to alert them of this as early as possible.</p>
                            <p>We needed to wait for the signal of the checkout in the form of payment to be able to show the correct messages.</p>
                            <p>It was crucial that during the payment phase of the journey, when they selected a payment gateway that was previously disabled, they were told the currency would change.</p>
                            <p><strong>Otherwise, the currency charged to the form of payment would be notified after the transaction was successful. This was the farthest from ideal.</strong></p>
                        </section>
                        <section>
                            <h3>Ideation of the experience</h3>
                            <img src={shopify1}  alt="ideation" />
                            <p>Afterpay, Bank Deposit, and Cash on Delivery (COD) are all multi-currency payment gateways previously disabled.</p>
                            <img src={shopify2} alt="ideation2" />
                            <p>Early iteration of displaying the messaging in checkout experience.</p>
                        </section>
                        <section>
                            <h3>Feedback from merchants, the Checkout UX team, etc.</h3>
                            <p>I sought feedback from multiple sources to ensure the quality of the proposed solution. Ultimately, the langugage changed but the concept held water and we went forth with this experience.</p>
                            <img src={shopify3} alt="Final version" />
                            <p>Both the payment method and the final amount on the right hand side of the checkout indicate that a charge in a different currency will occur.</p>
                            <p>I learned that it should be simplistic but forward in its presentation otherwise it would be too confusing or missed completely.</p>
                        </section>
                        <section>
                            <h1>The results are in</h1>
                            <p>Shipping this solution not only enabled previously unavailable payment gateways (like Afterpay, Cash on Deposit, Klarna, etc.) but also created the path forward to working on a true DCC experience that would open more doors for customers to utilize whatever currency they wanted to pay with.</p>
                            <h3>Multi-Currency Churn down, <br />Checkout completions up.</h3>
                            <p>Because of this change, we were able to see a 9% increase in checkout completions. Also, saw the churn rate of 21% down to 11%.Normalizing the curve a bit. </p>
                        </section>
                    </div>
                </main>
            </div>

        </Layout>
    )
}